import request from '@/utils/request'

//-------------用户管理---------

//获取账号管理人员数据
export function getUsers(data) {
  return request({
    url: '/sysmenu/SysuserMenuPage',
    method: 'post',
    params: data
  })
}

//新增系统用户
export function addUser(data) {
  return request({
    url: '/sysmenu/saveUser',
    method: 'post',
    data: data
  })
}

export function copyUser(data) {
  return request({
    url: '/sysmenu/copyUser',
    method: 'post',
    data: data
  })
}




//修改系统用户
export function updateUser(data) {
  return request({
    url: '/sysmenu/updateUser',
    method: 'post',
    data: data
  })
}

//删除系统用户
export function delUser(data) {
  return request({
    url: '/sysmenu/delUser',
    method: 'post',
    params: data
  })
}

//增加用户权限
export function addUserMenu(data) {
  return request({
    url: '/sysmenu/addUserMenu',
    method: 'post',
    params: data
  })
}


//获取省份
export function getUserCity(data) {
  return request({
    url: '/common/getUserCity',
    method: 'post',
    params: data
  })
}
//获取社区
export function getUserArea(data) {
  return request({
    url: '/sysmenu/getLevelcode',
    method: 'post',
    params: data
  })
}


//获取全部菜单数据，用来分配菜单
export function getAllMenus(data) {
  return request({
    url: '/common/getSysMenu',
    method: 'post',
    params: data
  })
}

//获取第三方部门
export function getDepartments(data) {
  return request({
    url: '/MA_MulRole/FindRoleList',
    method: 'post',
    params: data
  })
}



//-------------第三方部门管理---------
//部门列表
export function getDepartmentsByName(data) {
  return request({
    url: '/MA_MulRole/FindRolePage',
    method: 'post',
    params: data
  })
}

//新增部门
export function addDepartment(data) {
  return request({
    url: '/MA_MulRole/RoleAdd',
    method: 'post',
    params: data
  })
}

//修改部门
export function updateDepartment(data) {
  return request({
    url: '/MA_MulRole/RoleUpdate',
    method: 'post',
    params: data
  })
}

//删除部门
export function deleteDepartment(data) {
  return request({
    url: '/MA_MulRole/DelRole',
    method: 'post',
    params: data
  })
}




//获取用户所属社区集
export function communityOfUser(data) {
  return request({
    url: '/sysmenu/getLevelcodeByUser',
    method: 'post',
    params: data
  })
}

