<template>
  <div class="box">
    <div class="account-info" v-if="userInfo != null">
      <div><i class="el-icon-user"></i>昵称：{{ accountInfo.nickname }}</div>
      <!-- <div><i class="el-icon-switch-button"></i>最近登录时间：{{ accountInfo.logintime }}</div> -->
      <div><i class="el-icon-c-scale-to-original"></i>客户编号：{{ userInfo.accountuuid ? userInfo.accountuuid : '-' }}</div>
    </div>
    <div class="user-info" v-if="userInfo != null">
      <div><i class="el-icon-user"></i>姓名：{{ userInfo.name ? userInfo.name : '-' }}</div>
      <div>
        <i class="el-icon-c-scale-to-original"></i>身份证号：{{ userInfo.personcard ? userInfo.personcard : '-' }}
      </div>
      <div><i class="el-icon-phone"></i>联系方式：{{ userInfo.phone?userInfo.phone:'-' }}</div>

			<div><i class="el-icon-coin"></i>总积分：{{ accountInfo.point }}</div>
      <div>
        <i class="el-icon-coin"></i>可用积分：{{ accountInfo.usablepoint }}
      </div>
			<div></div>

      <div>
        <i class="el-icon-office-building"></i>社区名称：{{ userInfo.levelname ? userInfo.levelname : '-' }}
      </div>
      <div>
        <i class="el-icon-office-building"></i>小区名称：{{
          userInfo.regionname ? userInfo.regionname : "-"
        }}
      </div>
      <div>
        <i class="el-icon-school"></i>楼栋单元房号：{{
          userInfo.building && userInfo.danyuanhao && userInfo.roomnum
            ? userInfo.building +
              "-" +
              userInfo.danyuanhao +
              "-" +
              userInfo.roomnum
            : "-"
        }}
      </div>
      <div><i class="el-icon-school"></i>详细地址：{{ userInfo.address?userInfo.address:'-' }}</div>
      <div></div>
      <div></div>

      <div>
        <i class="el-icon-turn-off"></i>实名情况：
        <span v-if="!userInfo.userstate || userInfo.userstate === '0'">未实名用户</span>
        <span v-else>实名用户</span>
      </div>
      <div>
        <i class="el-icon-set-up"></i>审核状态：
        <span v-if="userInfo.checkflag == 0">待审核</span>
        <span v-if="userInfo.checkflag == 1">审核通过</span>
        <span v-if="userInfo.checkflag == 2">审核不通过</span>
      </div>
      <div v-if="userInfo.checkflag == 2">
        审核不通过原因：{{ userInfo.msg }}
      </div>
      <div v-if="userInfo.checkflag != 2"></div>

      
      <div><i class="el-icon-time"></i>创建时间：{{ userInfo.createtime }}</div>
      <div><i class="el-icon-switch-button"></i>最近登录时间：{{ accountInfo.logintime }}</div>
			<div></div>
    </div>
    <div
      style="width: 100%; height: 40px; font-size: 20px; text-align: center"
      v-else
    >
      未填写信息，暂无客户详情
    </div>
    <div style="text-align: center">
      <el-button type="primary" @click="closeDialog">关闭</el-button>
    </div>

    <!-- 水印 -->
    <div class="shuiyin">
      <div>{{ this.GLOBAL.phone() }}</div>
      <div>{{ this.GLOBAL.phone() }}</div>
      <div>{{ this.GLOBAL.phone() }}</div>
      <div v-if="userInfo != null">{{ this.GLOBAL.phone() }}</div>
      <div v-if="userInfo != null">{{ this.GLOBAL.phone() }}</div>
      <div v-if="userInfo != null">{{ this.GLOBAL.phone() }}</div>
      <div v-if="userInfo != null">{{ this.GLOBAL.phone() }}</div>
      <div v-if="userInfo != null">{{ this.GLOBAL.phone() }}</div>
      <div v-if="userInfo != null">{{ this.GLOBAL.phone() }}</div>
    </div>
  </div>
</template>

<script>
import { kehuDetail } from "@/api/kehulist/kehulist.js";
export default {
  props: {
    Mail: {
      type: String,
    },
  },

  mounted() {
    console.log(this.GLOBAL.phone());
    kehuDetail({
      userid: this.GLOBAL.adminId(),
      accountuuid: this.Mail,
    }).then((res) => {
      if (res.result == 200) {
        console.log(res);
        this.accountInfo = res.detail.accountinfo;
        this.userInfo = res.detail.userinfo;
      } else {
        this.$message.error(res.description);
      }
    });
  }, //新增还是会传递上次点修改得到的参数，所以新增传递一个参数判断，打开时清空表单
  data() {
    return {
      accountInfo: {},
      userInfo: null,
    };
  },
  computed: {},

  methods: {
    closeDialog() {
      this.$parent.$parent.formDialog = false;
    }, //获取父组件弹出框参数设置关闭
  },
};
</script>

<style>
</style>
<style scoped lang='scss'>
.box {
  position: relative;
  z-index: 1;
  .shuiyin {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5%;
    left: 0%;
    font-size: 40px;
    opacity: 0.1;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 33.33%);
    div {
      transform: translate(-50%, -50%);
      transform: rotate(-45deg);
    }
  }
  .account-info {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(1, 40px);
    i {
      margin-right: 5px;
    }
  }
  .user-info {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(7, 40px);
    i {
      margin-right: 5px;
    }
  }
}
</style>
