import request from '@/utils/request'


// 获取社区数据列表
export function kehuList(params) {
  return request({
    url: '/MA_UserAccount/FindUserAccountPage',
    method: 'get',
    params:params
  })
}

//客户详情
export function kehuDetail(params) {
    return request({
      url: 'MA_UserAccount/FindUserAccountInfo',
      method: 'get',
      params:params
    })
  }


//删除客户信息
export function deleteAccountInfo(params) {
  return request({
    url: 'MA_UserAccount/deleteAccountInfo',
    method: 'get',
    params:params
  })
}  

//积分流水
export function jifenList(params) {
  return request({
    url: '/MA_UserAccount/FindUserAccountPointPage',
    method: 'get',
    params:params
  })
}

//任务流水
export function renwuList(params) {
  return request({
    url: '/MA_UserAccount/FindUserAccountJoinVolActPage',
    method: 'get',
    params:params
  })
}

//活动流水
export function huodongList(params) {
  return request({
    url: '/MA_UserAccount/FindUserAccountJoinActPage',
    method: 'get',
    params:params
  })
}


//账户积分增加
export function addUserPoint(data) {
  return request({
    url: '/MA_UserAccount/adduserpoint',
    method: 'post',
    data: data
  })
}

//账户荣誉积分增加
export function addRyPoint(data) {
  return request({
    url: '/MA_UserAccount/addRypoint',
    method: 'post',
    data: data
  })
}
